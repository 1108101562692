import { ElementType } from '@generated/graphql';
import { Header, Text, TaskContainer, Video } from 'components/ElementsGenerator/';
import DownloadFiles, { DownloadFilesProps } from 'components/ElementsGenerator/elements/DownloadFiles';
import Feedback, { IFeedbackProps } from 'components/ElementsGenerator/elements/Feedback';
import ExerciseLink, { IExerciseLinkProps } from 'components/ElementsGenerator/elements/ExerciseLink';
import { ComponentType } from 'react';
import { ITextProps } from 'components/ElementsGenerator/elements/Text';
import { IHeaderProps } from 'components/ElementsGenerator/elements/Header';
import { VideoProps } from 'components/ElementsGenerator/elements/Video';
import { TaskContainerProps } from 'components/ElementsGenerator/TaskContainer';

type ElementPropsMap = {
  [ElementType.Question]: TaskContainerProps;
  [ElementType.File]: DownloadFilesProps;
  [ElementType.Video]: VideoProps;
  [ElementType.Heading]: IHeaderProps;
  [ElementType.ExerciseLink]: IExerciseLinkProps;
  [ElementType.Feedback]: IFeedbackProps;
  [ElementType.Text]: ITextProps;
};

type ElementComponent = {
  [K in ElementType]: ComponentType<ElementPropsMap[K]>;
};

const ELEMENT_COMPONENT: ElementComponent = {
  [ElementType.Question]: TaskContainer,
  [ElementType.File]: DownloadFiles,
  [ElementType.Video]: Video,
  [ElementType.Heading]: Header,
  [ElementType.ExerciseLink]: ExerciseLink,
  [ElementType.Feedback]: Feedback,
  [ElementType.Text]: Text,
};

type ElementsGeneratorProps<K extends ElementType> = {
  type: K;
  item: ElementPropsMap[K];
};

const ElementsGenerator = <K extends ElementType>(props: ElementsGeneratorProps<K>) => {
  const { type, item } = props;
  const Component = ELEMENT_COMPONENT[type] as ComponentType<typeof item>;
  return <Component {...item} />;
};

export default ElementsGenerator;
