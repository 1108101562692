import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { ElementFileType, useSendFileInChatBotMutation } from '@generated/graphql';
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';

export interface IFile {
  id: string;
  type: ElementFileType;
  fileName: string;
  file: { name: string; url: string };
}

const useFileDownload = () => {
  const { addToast } = useContext(ToastContext);
  const { me } = useContext(AuthContext);
  const isUserHasBot = me?.registerInBot;

  const [sendFileInChatBot, { loading }] = useSendFileInChatBotMutation();

  const onError = () =>
    addToast({
      type: ToastTypeEnum.ERROR,
      text: 'Произошла ошибка при скачивании файла',
    });

  const handleDownloadFile = (downloadFile: IFile) => {
    if (isUserHasBot) {
      sendFileInChatBot({
        variables: {
          elementFileId: downloadFile.id,
        },
      })
        .then((res) => {
          if (res.data?.sendFileInChatBot) {
            addToast({
              type: ToastTypeEnum.SUCCESS,
              text: 'Отправим файл тебе в бот',
            });
          } else onError();
        })
        .catch((err) => {
          console.error('Error useFileDownload:', err);
          onError();
        });
    } else {
      window.open(downloadFile.file.url);
    }
  };

  return { handleDownloadFile, loading };
};

export default useFileDownload;
