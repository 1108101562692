import { Stack, Typography, useTheme } from '@mui/material';
import SelectControl from 'components/form/SelectControl';
import { FC } from 'react';
import { CourseSubjectColor } from 'types/common';
import useThemeMode from 'hooks/useThemeMode';
import { useWatch } from 'react-hook-form';
import { CourseSubject } from '@generated/graphql';
import { MatchingAnswer } from 'pages/Lesson/Homework/types';

interface IElementPart {
  id: string;
  content?: string | null;
}

export type MatchingSelectProps = {
  elementParts?: Array<IElementPart> | null;
  name: string;
  matchingList: { label: string; value: string }[];
  subject: CourseSubjectColor;
  disabled?: boolean;
};

const MatchingSelect: FC<MatchingSelectProps> = ({ elementParts, name, matchingList, subject, disabled }) => {
  const { palette } = useTheme();
  const { mode } = useThemeMode();
  const matchValue: MatchingAnswer = useWatch({
    name,
  });

  return (
    <Stack spacing={2.5}>
      {elementParts?.map((item: IElementPart, index) => {
        const isCorrect = matchValue?.[index]?.isCorrect;
        const color = isCorrect ? palette[CourseSubject.Biology].main : palette.assessment[mode].fault;

        return (
          <Stack spacing={1} key={`match-answer-${item.id}`}>
            <Typography>{`${item.content}`}</Typography>
            <SelectControl
              sx={{
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  borderColor: typeof isCorrect === 'boolean' ? color : undefined,
                },
              }}
              name={`${name}.${index}`}
              list={matchingList}
              subject={subject}
              elementPartId={item.id}
              disabled={disabled}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MatchingSelect;
