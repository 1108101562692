import { Stack } from '@mui/material';
import { FC } from 'react';
import { ElementFileType } from '@generated/graphql';
import PromoCard from 'components/dashboard/PromoCard';
import useFileDownload, { IFile } from 'hooks/useFileDownload';

export type DownloadFilesProps = {
  files: IFile[] | null;
};

export interface IDownloadFiles {
  downloadFile: IFile;
  coverFile: IFile;
}

const DownloadFiles: FC<DownloadFilesProps> = ({ files }) => {
  const pairedFiles = files?.reduce((acc: IDownloadFiles[], current: IFile, index: number) => {
    if (current.type === ElementFileType.Cover) {
      acc.push({
        downloadFile: files[index + 1],
        coverFile: current,
      });
    } else return acc;
    return acc;
  }, []);

  const { handleDownloadFile } = useFileDownload();

  return (
    <Stack direction='row' spacing={2} justifyContent='space-between'>
      {pairedFiles?.map((file: IDownloadFiles) => <PromoCard image={file.coverFile.file.url} onClick={() => handleDownloadFile(file.downloadFile)} />)}
    </Stack>
  );
};

export default DownloadFiles;
