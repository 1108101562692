import TextFieldControl from 'components/form/TextFieldControl';
import { useWatch } from 'react-hook-form';
import { useTheme } from '@mui/material';
import useThemeMode from 'hooks/useThemeMode';
import { CourseSubject } from '@generated/graphql';

export interface ITextAnswerProps {
  name: string;
  placeholder?: string;
  disabled: boolean;
}

const TextAnswer = ({ name, placeholder, disabled }: ITextAnswerProps) => {
  const { palette } = useTheme();
  const { mode } = useThemeMode();
  const isCorrect: boolean | null | undefined = useWatch({
    name: `${name}.isCorrect`,
  });
  const color = isCorrect ? palette[CourseSubject.Biology].main : palette.assessment[mode].fault;

  return (
    <TextFieldControl
      disabled={disabled}
      name={`${name}.text`}
      sx={{
        '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: typeof isCorrect === 'boolean' ? color : undefined,
        },
      }}
      placeholder={placeholder || 'Введите ответ'}
    />
  );
};

export default TextAnswer;
