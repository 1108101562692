import { Box, Typography } from '@mui/material';
import useFileDownload, { IFile } from 'hooks/useFileDownload';
import UploadFileImage from 'assets/images/upload-file.svg';
import CircularLoading from 'components/CircularLoading';
import { LoadingButton } from '@mui/lab';

interface DownloadFileButtonProps {
  file: IFile;
}

const DownloadFileButton = ({ file }: DownloadFileButtonProps) => {
  const { handleDownloadFile, loading } = useFileDownload();
  return (
    <LoadingButton
      onClick={() => handleDownloadFile(file)}
      startIcon={<Box alt={`Картинка для вопроса ${file.fileName}`} component='img' src={UploadFileImage} sx={{ height: 40 }} />}
      loading={loading}
      loadingPosition='start'
      loadingIndicator={<CircularLoading size={40} />}
      sx={{
        borderRadius: 2.5,
        boxShadow: 8,
        backgroundColor: 'baseShift.500',
        padding: 1.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
      }}
    >
      <Typography variant='text7' sx={{ color: 'baseShift.text' }}>
        {file.fileName}
      </Typography>
    </LoadingButton>
  );
};

export default DownloadFileButton;
