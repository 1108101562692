import { IconButton, SvgIcon } from '@mui/material';
import { FieldArray, UseFieldArrayAppend } from 'react-hook-form/dist/types/fieldArray';
import { Attachment } from '@mui/icons-material';
import { ArrayPath } from 'react-hook-form';

interface FileField {
  file: File;
}

interface AttachmentButtonProps<T extends Record<string, FileField[]>> {
  append: UseFieldArrayAppend<T, ArrayPath<T>>;
  disabled?: boolean;
}

const AttachmentButton = <T extends Record<string, FileField[]>>({ append, disabled = false }: AttachmentButtonProps<T>) => (
  <IconButton component='label'>
    <input
      type='file'
      style={{ display: 'none' }}
      disabled={disabled}
      onChange={(event) => {
        const { files } = event.target;
        if (files && files.length > 0) {
          append(
            Array.from(files).map((file: File) => ({
              file,
            })) as FieldArray<T, ArrayPath<T>>[],
          );
        }
      }}
      multiple
    />
    <SvgIcon component={Attachment} sx={{ color: 'baseShift.text', transform: 'rotate(-45deg)' }} />
  </IconButton>
);

export default AttachmentButton;
