import { Card, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useMemo } from 'react';
import { AccessTime } from '@mui/icons-material';
import { format } from 'date-fns';
import AnswerGenerator from 'components/ElementsGenerator/elements/AnswerGenerator';
import { CourseSubjectColor } from 'types/common';
import Text from 'components/ElementsGenerator/elements/Text';
import { QuestionType } from '@generated/graphql';
import { UseFormReturn } from 'react-hook-form';
import { checkIsFileImg } from 'helpers/main';
import DownloadFileButton from 'components/buttons/DownloadFileButton';
import { IFile } from 'hooks/useFileDownload';
import Image from './Image';

export type TaskContainerProps = {
  placeholder?: string;
  totalTaskCount: number | undefined;
  currentTask: number;
  time?: Date;
  subject: CourseSubjectColor;
  title?: string;
  questionFiles?: IFile[] | null;
  questionType: QuestionType;
  name: string;
  elementParts?: Array<{ id: string; content?: string | null }> | null;
  form: UseFormReturn;
  description: string;
  correctAnswers?: Array<{ isCorrect?: boolean | null; content: string; correctAnswer?: string | null; order: number }> | undefined | null;
  matchingList?: { label: string; value: string }[];
};

const TaskContainer: FC<TaskContainerProps> = (props) => {
  const {
    totalTaskCount,
    currentTask,
    time,
    subject,
    description,
    title,
    questionFiles,
    questionType,
    correctAnswers,
    matchingList,
    name,
    form,
    elementParts,
    placeholder,
  } = props;

  const filesSortedByType = useMemo(
    () =>
      questionFiles?.reduce(
        (acc: { images: IFile[]; files: IFile[] }, currentFile: IFile) => {
          if (checkIsFileImg(currentFile.file.name)) {
            acc.images.push(currentFile);
          } else acc.files.push(currentFile);
          return acc;
        },
        { images: [], files: [] },
      ),
    [questionFiles],
  );

  const imagesCount = filesSortedByType?.images.length || 0;

  const getCorrectAnswerString = () => {
    if (!correctAnswers?.length) return undefined;
    if (questionType === QuestionType.TextAnswers) {
      return correctAnswers.map((answer) => answer.correctAnswer).join(', ');
    }
    if (questionType === QuestionType.Matching) {
      return correctAnswers.map((answer) => `\n${answer.content} - ${answer.correctAnswer}`).join(', ');
    }
    return correctAnswers.map((answer) => answer.order).join(', ');
  };

  const correctAnswer = getCorrectAnswerString();

  return (
    <Card sx={{ borderRadius: 4 }}>
      <Stack direction='row' justifyContent='space-between' mb={1.5}>
        <Typography sx={(theme) => ({ color: theme.palette[subject].main })} variant='text2'>
          {`${currentTask}/${totalTaskCount}`}
        </Typography>
        {time && (
          <Stack direction='row' alignItems='center'>
            <AccessTime
              sx={(theme) => ({
                color: theme.palette[subject].main,
                width: 24,
                height: 24,
                mr: 0.5,
              })}
            />
            <Typography variant='text3'>{format(time, 'HH:mm:ss')}</Typography>
          </Stack>
        )}
      </Stack>
      <Stack spacing={2}>
        {title && <Text content={title} />}
        {imagesCount > 0 && (
          <Grid container spacing={1}>
            {filesSortedByType?.images.map((img) => (
              <Grid key={`question-img-${img.id}`} size={imagesCount > 1 ? 3 : 12}>
                <Image
                  questionImg={img.file.url}
                  sx={{
                    ...(imagesCount === 1 && {
                      aspectRatio: 'auto',
                      '& > img': { height: 'auto', maxHeight: 320, display: 'block', mx: 'auto', maxWidth: '100%' },
                    }),
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!!filesSortedByType?.files?.length && filesSortedByType?.files.map((file) => <DownloadFileButton key={`question-file-${file.id}`} file={file} />)}
        {description && <Text content={description} />}
        <AnswerGenerator
          subject={subject}
          questionType={questionType}
          elementParts={elementParts}
          name={name}
          placeholder={placeholder}
          form={form}
          matchingList={matchingList}
          disabled={!!correctAnswer}
        />
        {correctAnswer ? (
          <Typography variant='text4' sx={{ whiteSpace: 'pre-wrap' }}>
            {`Правильный ответ: ${correctAnswer}`}
          </Typography>
        ) : null}
      </Stack>
    </Card>
  );
};
export default TaskContainer;
