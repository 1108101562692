import { IconButton, Stack } from '@mui/material';
import TextFieldControl from 'components/form/TextFieldControl';
import { Close } from '@mui/icons-material';
import { ArrayPath, UseFormReturn } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import Files from 'components/form/TextFieldWithAttachments/Files';
import { useEffect } from 'react';
import { get } from 'lodash';

interface TextFieldWithAttachmentsProps<T extends Record<string, any>> {
  form: UseFormReturn<T>;
  messageField?: keyof T & string;
  filesField?: ArrayPath<T>;
  onCloseClick: () => void;
  placeholder: string;
  messageRules?: RegisterOptions;
  disabled?: boolean;
}

const TextFieldWithAttachments = <T extends Record<string, any>>({
  form,
  onCloseClick,
  placeholder,
  messageRules,
  messageField = 'message',
  filesField = 'files' as ArrayPath<T>,
  disabled = false,
}: TextFieldWithAttachmentsProps<T>) => {
  const {
    control,
    formState: { errors },
    reset,
  } = form;

  const isError = get(errors, messageField) || get(errors, filesField);

  useEffect(
    () => () => {
      reset({ [filesField]: [], [messageField]: '' } as any);
    },
    [reset, messageField, filesField],
  );

  return (
    <Stack
      sx={{
        boxShadow: 4,
        borderRadius: '10px',
        px: 1.5,
        py: 1.6,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: isError ? 'error.main' : 'transparent',
      }}
    >
      <Stack direction='row' alignItems='flex-start'>
        <TextFieldControl
          name={messageField}
          multiline
          minRows={7}
          rules={messageRules}
          placeholder={placeholder}
          disabled={disabled}
          sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& .MuiInputBase-root': { p: 0 } }}
        />
        <IconButton onClick={onCloseClick} sx={{ color: 'baseShift.200' }}>
          <Close />
        </IconButton>
      </Stack>
      <Files<T> control={control} filesField={filesField} disabled={disabled} />
    </Stack>
  );
};

export default TextFieldWithAttachments;
