import { Theme } from '@mui/material';
import { CourseSubjectColor } from 'types/common';
import { useInitData } from '@vkruglikov/react-telegram-web-app';

export type BackgroundType = 'background' | 'mainBackground';

export const getBackground = (theme: Theme, subject: CourseSubjectColor | undefined, backgroundType: BackgroundType) => {
  const isLightMode = theme.palette.mode === 'light';
  if (isLightMode) {
    if (subject) return theme.palette[subject][backgroundType];
    return theme.palette.background.default;
  }
  return theme.palette.base[900];
};

type UseUsernameParams =
  | {
      firstName?: string | null | undefined;
      telegramUsername?: string | null | undefined;
    }
  | undefined
  | null;

export const useUsername = (user: UseUsernameParams) => {
  const [initDataUnsafe] = useInitData();
  const telegramUsername = initDataUnsafe?.user?.username || user?.telegramUsername;
  return telegramUsername ? `@${telegramUsername}` : user?.firstName;
};

export const getFileBaseName = (fileNameWithExtension: string | undefined | null): string | null => {
  if (!fileNameWithExtension) return null;

  const dotIndex = fileNameWithExtension.lastIndexOf('.');

  if (dotIndex === -1) {
    return null;
  }

  return fileNameWithExtension.substring(0, dotIndex);
};

export const getFileExtension = (fileNameWithExtension: string | undefined | null): string | null => {
  if (!fileNameWithExtension) return null;

  const dotIndex = fileNameWithExtension.lastIndexOf('.');
  if (dotIndex === -1) {
    return null;
  }

  return fileNameWithExtension.substring(dotIndex + 1);
};

export const IMAGE_EXTENSIONS: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'ico', 'heic', 'heif', 'raw', 'psd', 'ai', 'eps'];

export const checkIsFileImg = (fileName: string) => {
  const extension = getFileExtension(fileName);
  return extension ? IMAGE_EXTENSIONS.includes(extension) : false;
};
