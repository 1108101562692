import { Form } from 'components/form/Form';
import { UseFormReturn } from 'react-hook-form';
import TextFieldWithAttachments from 'components/form/TextFieldWithAttachments';
import { SecondPartAnswerForm } from './SecondPartAnswer';

interface UserAnswerFormProps {
  form: UseFormReturn<SecondPartAnswerForm>;
  hideForm: () => void;
}

const UserAnswerForm = ({ form, hideForm }: UserAnswerFormProps) => (
  <Form form={form}>
    <TextFieldWithAttachments form={form} onCloseClick={hideForm} placeholder='Введи свой комментарий' messageRules={{ required: true }} />
  </Form>
);

export default UserAnswerForm;
