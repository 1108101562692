import Grid from '@mui/material/Grid2';
import FileChip from 'components/form/TextFieldWithAttachments/FileChip';
import AttachmentButton from 'components/form/TextFieldWithAttachments/AttachmentButton';
import { ArrayPath, Control, useFieldArray } from 'react-hook-form';

interface FileField {
  formId: string;
  file: File;
}

interface FilesProps<T extends Record<keyof T, FileField[]>> {
  control: Control<T>;
  filesField: ArrayPath<T>;
  disabled: boolean;
}

const Files = <T extends Record<keyof T, FileField[]>>({ control, filesField, disabled = false }: FilesProps<T>) => {
  const { fields, append, remove } = useFieldArray<T, ArrayPath<T>, 'formId'>({
    name: filesField,
    control,
    keyName: 'formId',
  });

  return (
    <Grid container spacing={2}>
      <Grid container columnSpacing={1} rowSpacing={2} size={{ xs: 12, md: 11 }} wrap='wrap'>
        {fields?.length
          ? fields.map((fileField, index) => {
              const typedFileField = fileField as unknown as FileField;
              return (
                <Grid size={{ xs: 12, sm: 3 }} key={fileField.formId}>
                  <FileChip file={typedFileField.file} remove={remove} index={index} />
                </Grid>
              );
            })
          : null}
      </Grid>
      <Grid size={{ xs: 12, md: 1 }} alignItems='flex-end' display='flex'>
        <AttachmentButton<T> append={append} disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default Files;
