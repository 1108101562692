import { generatePath } from 'react-router-dom';
import ROUTES from 'constants/routes';
import qs from 'qs';
import { HomeworkParams } from '../types';

type Params = Readonly<Partial<HomeworkParams>>;

export const getExercisePath = (params: Params, state: 'result' | 'preview' | 'resultAnswer' | 'previewAnswer') =>
  generatePath(`/${ROUTES.EXERCISE}/${state}`, params);

export const getElementPath = (params: Params, elementId: string, searchParams: URLSearchParams) => ({
  pathname: generatePath(`/${ROUTES.ELEMENT}`, {
    ...params,
    elementId,
  }),
  search: qs.stringify(Object.fromEntries(searchParams.entries())),
});

export const getBlockPath = (params: Params) => generatePath(`/${ROUTES.BLOCK}`, params);
