import React from 'react';
import { SxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface ITextProps {
  content: string;
  sx?: SxProps;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  '& p': { m: 0, mb: 1 },
  '& a': { wordBreak: 'break-all', textDecoration: 'underline' },
  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    '&.img_resized': {
      height: 'auto',
    },
  },
  '& p + .image.image-style-align-left, & p + .image.image-style-align-right, & p + .image.image-style-side': {
    marginTop: 0,
  },
  '& .image-inline.image-style-align-left, & .image-inline.image-style-align-right': {
    marginTop: '.6em',
    marginBottom: '.6em',
  },
  '& .image-inline.image-style-align-left': {
    marginRight: '.6em',
  },
  '& .image-inline.image-style-align-right': {
    marginLeft: '.6em',
  },
  '& figure': {
    margin: 0,
  },
  '& .image-inline': {
    display: 'inline-flex',
    maxWidth: '100%',
    alignItems: 'flex-start',
  },
  '& .image-inline picture': {
    display: 'flex',
  },
  '& .image-inline picture, & .image-inline img': {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: '100%',
  },
  '& .image-style-align-left': {
    float: 'left',
    marginRight: '1.5em',
  },
  '& .image-style-align-right': {
    float: 'right',
    marginLeft: '1.5em',
  },
  '& .image-style-align-center': {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '& .image': {
    display: 'table',
    clear: 'both',
    textAlign: 'center',
    margin: '0.9em auto',
    minWidth: 50,
    zIndex: 1,
    '&.image_resized': {
      maxWidth: '100%',
      display: 'block',
      boxSizing: 'border-box',
      '& > figcaption': {
        display: 'block',
      },
      '& img': {
        width: '100%',
      },
    },
    '&.image-style-align-right': {
      float: 'right',
      marginLeft: '1.5em',
    },
    '&.image-style-align-left': {
      float: 'left',
      marginRight: '1.5em',
    },
    '&.image-style-align-left, &.image-style-align-right': {
      maxWidth: 'calc(100% - 1.5em)',
      clear: 'none',
    },
    '&.image-style-side': {
      float: 'right',
      marginLeft: '1.5em',
      maxWidth: '50%',
    },
    '&.image-style-block-align-right': {
      marginRight: 0,
      marginLeft: 'auto',
    },
    '&.image-style-block-align-left': {
      marginLeft: 0,
      marginRight: 'auto',
    },
    '& > figcaption': {
      display: 'table-caption',
      captionSide: 'bottom',
      wordBreak: 'break-word',
      color: theme.palette.base[700],
      backgroundColor: theme.palette.base[200],
      padding: '.6em',
      fontSize: '.75em',
      outlineOffset: -1,
    },
    '& img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: '100%',
      minWidth: '100%',
      height: 'auto',
    },
  },
}));

const Text = ({ content, sx }: ITextProps) => (
  <StyledTypography
    variant='text3'
    sx={{
      ...sx,
    }}
    dangerouslySetInnerHTML={{
      __html: content?.replaceAll('color:#000000;', ''),
    }}
  />
);
export default Text;
