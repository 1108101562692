import {
  ElementType,
  ExerciseLinkElementLkDto,
  ExerciseQuery,
  FeedbackElementLkDto,
  FileElementLkDto,
  HeadingElementLkDto,
  QuestionElementLkDto,
  TextElementLkDto,
  VideoElementLkDto,
} from '@generated/graphql';

export type HomeworkParams = { exerciseId: string; blockId: string; lessonId: string; elementId: string };
export type ElementsArray = ExerciseQuery['exercise']['elements'];
export type ExerciseElement = NonNullable<ExerciseQuery['exercise']['elements']>[number];
/*
eslint-disable no-underscore-dangle
*/

export const isQuestionElement = (element: ExerciseElement): element is QuestionElementLkDto =>
  element.__typename === 'QuestionElementLkDto' || element.type === ElementType.Question;

export const isFileElementLkDto = (element: ExerciseElement): element is FileElementLkDto => element.__typename === 'FileElementLkDto';

export const isExerciseLinkElementLkDto = (element: ExerciseElement): element is ExerciseLinkElementLkDto => element.__typename === 'ExerciseLinkElementLkDto';

export const isFeedbackElementLkDto = (element: ExerciseElement): element is FeedbackElementLkDto => element.__typename === 'FeedbackElementLkDto';

export const isHeadingElementLkDto = (element: ExerciseElement): element is HeadingElementLkDto => element.__typename === 'HeadingElementLkDto';

export const isTextElementLkDto = (element: ExerciseElement): element is TextElementLkDto => element.__typename === 'TextElementLkDto';

export const isVideoElementLkDto = (element: ExerciseElement): element is VideoElementLkDto => element.__typename === 'VideoElementLkDto';

export type TextAnswer = { text: string; isCorrect: boolean };
export type FewAnswers = { [key: string]: { checked: boolean; isCorrect: boolean } };
export type MatchingAnswerItem = { text: string; elementPartId: string; isCorrect: boolean | undefined };
export type MatchingAnswer = Array<{ text: string; elementPartId: string; isCorrect: boolean | undefined } | undefined>;
export type SingleAnswer = { checked: string; isCorrect: boolean };
export type FileAnswer = { files?: Array<{ id?: string; file: File | { name: string; url: string } }>; text?: string };

export type Answer = TextAnswer | FewAnswers | MatchingAnswer | SingleAnswer | FileAnswer;

export type ElementForm = { [key in string]: Answer };
