import shadows, { Shadows } from '@mui/material/styles/shadows';

export default [
  'none',
  '0px 0px 9px rgba(35, 35, 102, 0.19)',
  '0px 0px 17px rgba(171, 165, 165, 0.40)',
  '0px 0px 5px rgba(47, 47, 102, 0.13)',
  '0px 0px 13px rgba(160, 169, 181, 0.29)',
  '0px 0px 50px 0px rgba(75, 125, 255, 0.10)',
  '0px 0px 16px 0px rgba(255, 255, 255, 0.13)',
  '0px 4px 30px 0px rgba(215, 215, 231, 0.53)',
  '0px 0px 5px 0px rgba(47, 47, 102, 0.13)',
  ...shadows.slice(9, 25),
] as Shadows;
