import { useOutletContext } from 'react-router-dom';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import {
  ElementsArray,
  isExerciseLinkElementLkDto,
  isFeedbackElementLkDto,
  isFileElementLkDto,
  isHeadingElementLkDto,
  isQuestionElement,
  isTextElementLkDto,
  isVideoElementLkDto,
} from 'pages/Lesson/Homework/types';
import { ExerciseQuery } from '@generated/graphql';
import { UseFormReturn } from 'react-hook-form';
import useCurrentElement from './useCurrentElement';

interface IUseItemProps {
  elements: ElementsArray;
  exercise: ExerciseQuery['exercise'];
  form: UseFormReturn;
}

const useItemProps = ({ elements, exercise, form }: IUseItemProps) => {
  const { subject } = useOutletContext<IExerciseOutletContext>();
  const { currentElement, currentElementOrder, totalTaskCount, currentElementName } = useCurrentElement(elements, exercise);

  if (!currentElement) return null;

  const isQuestion = isQuestionElement(currentElement);
  const isFileElement = isFileElementLkDto(currentElement);
  const isExerciseLinkElement = isExerciseLinkElementLkDto(currentElement);
  const isFeedbackElement = isFeedbackElementLkDto(currentElement);
  const isHeadingElement = isHeadingElementLkDto(currentElement);
  const isTextElement = isTextElementLkDto(currentElement);
  const isVideoElement = isVideoElementLkDto(currentElement);

  const { id, order } = currentElement;

  const itemProps = {
    id,
    order,
    subject,
    name: currentElementName,
    currentTask: currentElementOrder,
    totalTaskCount,
    form,
    questionFiles: isQuestion || isFileElement ? currentElement?.files : undefined,
    ...(isExerciseLinkElement && {
      description: currentElement.description,
    }),
    ...(isFeedbackElement && {
      elementParts: currentElement.elementParts,
    }),
    ...(isFileElement && {
      files: currentElement.files,
    }),
    ...(isHeadingElement && {
      title: currentElement.title,
    }),
    ...(isQuestion && {
      questionFiles: currentElement.files,
      correctAnswers: currentElement.correctAnswers,
      title: currentElement.title,
      description: currentElement.description,
      questionType: currentElement.questionType,
      elementParts: currentElement.elementParts,
      files: currentElement.files,
      disabled: exercise.userStatistic?.scoreA !== null,
      isError: currentElement.currentUserProgress?.isCorrect === false,
      placeholder: currentElement.placeholder || 'Введите ответ',
      matchingList: currentElement.matchingAnswers?.map((match: string) => ({
        label: match,
        value: match,
      })),
    }),
    ...(isTextElement && {
      content: currentElement.content,
    }),
    ...(isVideoElement && {
      kinescopeId: currentElement.kinescopeId,
    }),
  };

  return itemProps;
};

export default useItemProps;
