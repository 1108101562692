import { Card, Stack } from '@mui/material';
import { BackButton } from 'components/index';
import { Header } from 'components/ElementsGenerator';
import React from 'react';
import { TestState } from 'pages/Lesson';
import { getBlockPath, getExercisePath } from 'pages/Lesson/Homework/utils/navigationHelpers';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { HomeworkParams } from 'pages/Lesson/Homework/types';
import { ExerciseQuery } from '@generated/graphql';

interface ElementHeaderProps {
  exercise: ExerciseQuery['exercise'];
}

const ElementHeader = ({ exercise }: ElementHeaderProps) => {
  const navigate = useNavigate();
  const params = useParams<HomeworkParams>();

  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');

  const handleBackNavigation = () => {
    if (state === TestState.previewAnswer || state === TestState.resultAnswer) {
      const nextState = state.includes('result') ? TestState.result : TestState.preview;
      navigate({
        pathname: getExercisePath(params, nextState),
      });
    } else {
      navigate({
        pathname: getBlockPath(params),
      });
    }
  };
  const backButtonText = state === TestState.previewAnswer || state === TestState.resultAnswer ? 'Назад к результатам' : 'Назад к урокам';

  return (
    <Card sx={{ mb: 2.5 }} component={Stack} spacing={2.5}>
      <BackButton navigate={handleBackNavigation} text={backButtonText} />
      <Header title={exercise?.title} />
    </Card>
  );
};

export default ElementHeader;
