import { useParams } from 'react-router-dom';
import { ExerciseQuery, QuestionType } from '@generated/graphql';
import { ElementsArray, ExerciseElement, HomeworkParams, isQuestionElement } from '../types';

const useCurrentElement = (elements: ElementsArray, exercise: ExerciseQuery['exercise']) => {
  const params = useParams<HomeworkParams>();
  const { elementId } = params;

  const { completeness } = exercise;

  const isExerciseDone = completeness?.isDone;
  const isExerciseComplete = completeness?.isComplete;

  const elementsList = isExerciseDone ? elements?.filter((element) => 'questionType' in element && element.questionType !== QuestionType.FileAnswer) : elements;

  const currentElement: ExerciseElement | undefined = elementId ? elements?.find((element: ExerciseElement) => element.id === elementId) : elements?.[0];
  const currentElementOrder = currentElement?.order || 0;
  const isQuestion = currentElement && isQuestionElement(currentElement);

  const isFirstQuestion = elementsList?.length && currentElement ? currentElementOrder === 1 : false;
  const isLastQuestion = elementsList?.length && currentElement ? currentElementOrder === elementsList.length : false;
  const isProgressDone = isQuestion ? currentElement?.correctAnswers?.length : completeness?.isDone;
  const totalTaskCount = elementsList?.length;

  const elementType = currentElement?.type;

  const currentElementName = `answer-${currentElement?.id}`;

  return {
    currentElement,
    currentElementOrder,
    isFirstQuestion,
    isLastQuestion,
    isProgressDone,
    totalTaskCount,
    elementsList,
    elementId,
    isExerciseComplete,
    elementType,
    currentElementName,
  };
};

export default useCurrentElement;
