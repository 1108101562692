import { IconButton, Stack } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import React, { useContext } from 'react';
import useCurrentElement from 'pages/Lesson/Homework/hooks/useCurrentElement';
import { ExerciseQuery, useCreateUserAnswerMutation } from '@generated/graphql';
import { Answer, ElementForm, ElementsArray, HomeworkParams, isQuestionElement } from 'pages/Lesson/Homework/types';
import { checkAnswerIsReady, prepareAnswerInput } from 'pages/Lesson/Homework/utils/utils';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { generatePath, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { getElementPath } from 'pages/Lesson/Homework/utils/navigationHelpers';
import { FieldError, FieldErrors, FieldErrorsImpl, Merge, UseFormReturn } from 'react-hook-form';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import { isObject } from 'lodash';

interface ElementsNavigationProps {
  exercise: ExerciseQuery['exercise'];
  form: UseFormReturn<ElementForm>;
}

const ElementsNavigation = ({ exercise, form }: ElementsNavigationProps) => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const params = useParams<HomeworkParams>();
  const [searchParams] = useSearchParams();
  const { handleSubmit } = form;

  const { subject } = useOutletContext<IExerciseOutletContext>();

  const [saveUserAnswer, { loading: saveLoading }] = useCreateUserAnswerMutation();

  const elements: ElementsArray = exercise?.elements || [];
  const { isLastQuestion, isFirstQuestion, isProgressDone, currentElementOrder, elementsList, currentElement, currentElementName } = useCurrentElement(
    elements,
    exercise,
  );

  const handleChangeTask = (direction?: 'next') => {
    const isForward = direction === 'next';
    const newTaskNumber = isForward ? currentElementOrder + 1 : currentElementOrder - 1;
    if (isLastQuestion && isForward) {
      navigate({
        pathname: generatePath(`/${ROUTES.EXERCISE}/${isProgressDone ? 'result' : 'preview'}`, params),
      });
    } else {
      navigate(getElementPath(params, elementsList![newTaskNumber - 1].id, searchParams));
    }
  };

  const isQuestion = currentElement && isQuestionElement(currentElement);

  const getErrorMessage = (errObj: FieldErrors<ElementForm> | Merge<FieldError, FieldErrorsImpl<NonNullable<Answer>>>): void => {
    if (isObject(errObj)) {
      const values = Object.values(errObj);
      for (let i = 0; i < values.length; i += 1) {
        const currentProp = values[i];
        if (isObject(currentProp)) {
          if ('message' in currentProp && typeof currentProp.message === 'string') {
            addToast({ type: ToastTypeEnum.ERROR, text: currentProp.message });
            return;
          }
          getErrorMessage(currentProp);
        }
      }
    }
  };

  const onFormError = (error: FieldErrors<ElementForm>) => getErrorMessage(error);

  const onSubmit = (formData: ElementForm) => {
    const answer = formData[currentElementName];

    const isAnswerReady = checkAnswerIsReady(answer, currentElement);

    if (!isAnswerReady || !isQuestion || isProgressDone || !answer) {
      handleChangeTask('next');
    } else {
      saveUserAnswer({
        variables: {
          input: prepareAnswerInput(currentElement, answer),
        },
      })
        .then(() => handleChangeTask('next'))
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
    }
  };

  return (
    <Stack
      sx={{
        mt: 2,
        flex: 1,
      }}
      direction='row'
      spacing={1}
      alignItems='flex-end'
    >
      {!isFirstQuestion && (
        <IconButton variant='contained' onClick={() => handleChangeTask()} size='large'>
          <KeyboardArrowLeft />
        </IconButton>
      )}

      <LoadingButton loading={saveLoading} variant='contained' fullWidth color='secondary' subject={subject} onClick={handleSubmit(onSubmit, onFormError)}>
        {isLastQuestion ? 'Завершить' : 'Следующее задание'}
      </LoadingButton>
    </Stack>
  );
};

export default ElementsNavigation;
