import { Stack, useTheme } from '@mui/material';
import CheckboxControl from 'components/form/CheckboxControl';
import { FC } from 'react';
import { IRadioButtonProps } from 'components/form/RadioButtonControl';
import generateList from 'components/form/generateList';
import { CourseSubjectColor } from 'types/common';
import { UseFormReturn } from 'react-hook-form';
import { CourseSubject } from '@generated/graphql';
import useThemeMode from 'hooks/useThemeMode';

export type CheckboxGroupControlProps = {
  itemList: IRadioButtonProps[] | string[] | any[];
  name: string;
  subject: CourseSubjectColor;
  disabled?: boolean;
  form: UseFormReturn;
};

const CheckboxGroupControl: FC<CheckboxGroupControlProps> = ({ itemList, name, subject, disabled = false, form }) => {
  const { palette } = useTheme();
  const { mode } = useThemeMode();

  return (
    <Stack spacing={1.5}>
      {itemList?.map((item, index) => {
        const itemData = typeof item === 'string' ? generateList(item, index) : { label: item.content, value: item.id };
        const { label, value } = itemData;
        const isCorrect = form.getValues(`${name}.${value}.isCorrect`);
        const color = isCorrect ? palette[CourseSubject.Biology].main : palette.assessment[mode].fault;
        return (
          <CheckboxControl
            sx={{
              '& p': {
                color: typeof isCorrect !== 'undefined' ? color : undefined,
              },
              '& .MuiCheckbox-root.Mui-checked': {
                color: typeof isCorrect !== 'undefined' ? color : undefined,
              },
            }}
            key={`${name}.${value}`}
            name={`${name}.${value}.checked`}
            label={`${index + 1}) ${label}`}
            subject={subject}
            disabled={disabled}
          />
        );
      })}
    </Stack>
  );
};

export default CheckboxGroupControl;
