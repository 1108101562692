import { Form } from 'components/form/Form';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import ElementsGenerator from 'components/ElementsGenerator/ElementsGenerator';
import { ExerciseQuery, QuestionType } from '@generated/graphql';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import useItemProps from './hooks/useItemProps';
import SecondPartAnswer from './SecondPartAnswer/SecondPartAnswer';
import useCurrentElement from './hooks/useCurrentElement';
import ElementsNavigation from './components/ElementsNavigation';
import ElementHeader from './components/ElementHeader';
import { initializeForm } from './utils/utils';
import { ElementForm, ElementsArray, isQuestionElement } from './types';

const Homework: FC<{ exercise: ExerciseQuery['exercise'] }> = ({ exercise }) => {
  const { lesson } = useOutletContext<IExerciseOutletContext>();

  const elements: ElementsArray = exercise?.elements || [];

  const { elementsList, currentElement, isExerciseComplete, elementType } = useCurrentElement(elements, exercise);

  const form = useForm<ElementForm>({
    defaultValues: initializeForm(elements),
    reValidateMode: 'onSubmit',
  });

  const itemProps = useItemProps({ elements, exercise, form });

  if (!currentElement || !itemProps || !elementType) {
    return null;
  }

  const isQuestion = isQuestionElement(currentElement);

  const isFileAnswerQuestion = isQuestion && currentElement.questionType === QuestionType.FileAnswer;
  const shouldShowSecondPartAnswer = lesson.hasFileAnswerQuestion && isFileAnswerQuestion && isExerciseComplete;

  if (shouldShowSecondPartAnswer) {
    return <SecondPartAnswer currentElement={currentElement} userStatistic={exercise.userStatistic} />;
  }
  return (
    <>
      <ElementHeader exercise={exercise} />
      <Form form={form}>
        <ElementsGenerator type={elementType} item={itemProps} />
      </Form>
      {!!elementsList?.length && <ElementsNavigation exercise={exercise} form={form} />}
    </>
  );
};

export default Homework;
