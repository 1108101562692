import { Chip, Tooltip, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { UseFieldArrayRemove } from 'react-hook-form';

interface IFileChipProps {
  file: File;
  remove: UseFieldArrayRemove;
  index: number;
}

const FileChip = ({ file, remove, index }: IFileChipProps) => {
  const { typography } = useTheme();
  const fileData = file;
  return (
    <Tooltip title={fileData.name}>
      <Chip
        label={fileData.name}
        size='medium'
        onDelete={() => remove(index)}
        deleteIcon={<Close />}
        sx={{
          bgcolor: 'baseShift.400',
          color: 'baseShift.text',
          minWidth: 130,
          ...typography.text7,
        }}
      />
    </Tooltip>
  );
};

export default FileChip;
