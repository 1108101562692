import { Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { IOutletContext } from 'types/common';
import { ExerciseType } from '@generated/graphql';
import { LessonCardProps } from './LessonCard.types';
import { generateDateText, getStatus, isLessonMissed } from './LessonCard.utils';

const LessonCard: FC<LessonCardProps> = ({ lesson, index }) => {
  const { isAvailable, id, title, dateTo, completeness, dateFrom } = lesson;
  const { subject } = useOutletContext<IOutletContext>();

  const dateText = generateDateText(dateTo, dateFrom);
  const missedLesson = isLessonMissed(dateTo);
  const statusIcon = getStatus({
    isLessonAvailable: isAvailable,
    completeness,
    subject,
    dateTo,
    unavailableReason: lesson.unavailableReason,
  });

  const routeToExercise = () => {
    const exerciseRoute = `${id}/${lesson?.exercises?.[0]?.id}`;
    if (lesson?.exercises?.[0]?.type === ExerciseType.Homework && (completeness?.isComplete || completeness?.isDone)) {
      return `${exerciseRoute}/info`;
    }
    return exerciseRoute;
  };

  return (
    <Stack
      component={isAvailable ? Link : 'div'}
      direction='row'
      justifyContent='space-between'
      sx={{ cursor: !isAvailable ? 'default' : 'pointer' }}
      to={routeToExercise()}
      spacing={1.5}
    >
      <Stack spacing={0.5} direction='row'>
        <Typography variant='text3' sx={{ color: !isAvailable ? 'base.300' : undefined }}>
          {`${index + 1}. `}
        </Typography>
        <Stack spacing={1} alignItems='baseline'>
          <Typography variant='text3' sx={{ color: !isAvailable ? 'base.300' : undefined }}>
            {title}
          </Typography>
          {dateText && (
            <Chip
              size='small'
              label={dateText}
              disabled={!isAvailable && !missedLesson}
              sx={{
                color: missedLesson ? 'error.main' : 'inherit',
              }}
            />
          )}
        </Stack>
      </Stack>

      {statusIcon}
    </Stack>
  );
};

export default LessonCard;
