import { CreateUserAnswerInput, QuestionType } from '@generated/graphql';
import validateAnswerType from './validateAnswerType';
import {
  Answer,
  ElementsArray,
  ExerciseElement,
  FewAnswers,
  FileAnswer,
  isQuestionElement,
  MatchingAnswer,
  MatchingAnswerItem,
  SingleAnswer,
  TextAnswer,
} from '../types';

export const initializeForm = (elements: ElementsArray) =>
  elements?.reduce((acc: object, currentElement: ExerciseElement) => {
    const isQuestion = 'questionType' in currentElement;
    if (isQuestion) {
      const { questionType, currentUserAnswers, correctAnswers, id: elementId, elementParts, currentUserProgress } = currentElement;
      if (currentUserAnswers) {
        const [firstAnswer] = currentUserAnswers;
        const getAnswerObj = (checked: boolean | string | null | undefined, isCorrect: boolean | undefined) => ({
          checked,
          isCorrect,
        });
        switch (questionType) {
          case QuestionType.TextAnswers: {
            return {
              ...acc,
              [`answer-${elementId}`]: {
                text: firstAnswer?.answer,
                isCorrect: currentUserProgress?.isCorrect,
              },
            };
          }
          case QuestionType.SingleAnswer: {
            const checkedAnswer = firstAnswer?.elementPartId;
            const isCorrect = correctAnswers?.some(({ id }) => id === checkedAnswer);
            return {
              ...acc,
              [`answer-${elementId}`]: getAnswerObj(checkedAnswer, isCorrect),
            };
          }
          case QuestionType.FewAnswers: {
            const answers = elementParts?.reduce((answersAcc, part) => {
              const isChecked = currentUserAnswers.some((a) => a.elementPartId === part.id);
              const isCorrect = isChecked ? correctAnswers?.some(({ id }) => id === part.id) : undefined;
              return {
                ...answersAcc,
                [part.id]: getAnswerObj(isChecked, isCorrect),
              };
            }, {});
            return {
              ...acc,
              [`answer-${elementId}`]: answers,
            };
          }

          case QuestionType.Matching: {
            return {
              ...acc,
              [`answer-${elementId}`]: currentUserAnswers.map(({ answer: userAnswer, elementPartId }) => {
                const isCorrect = correctAnswers?.some(({ correctAnswer, id }) => id === elementPartId && correctAnswer === userAnswer);
                return {
                  text: userAnswer,
                  elementPartId,
                  isCorrect,
                };
              }),
            };
          }
          case QuestionType.FileAnswer: {
            return {
              ...acc,
              [`answer-${elementId}`]: {
                text: currentUserAnswers?.[0]?.answer,
                files: currentUserAnswers?.[0]?.files,
              },
            };
          }
          default:
            return acc;
        }
      }
    }
    return acc;
  }, {});

export const checkAnswerIsReady = (answer: Answer, element: ExerciseElement | undefined) => {
  if (element && isQuestionElement(element)) {
    const { questionType } = element;

    validateAnswerType(questionType, answer);

    switch (questionType) {
      case QuestionType.FewAnswers: {
        const fewAnswer = answer as FewAnswers;
        return Object.entries(fewAnswer).some(([, value]) => value.checked);
      }
      case QuestionType.TextAnswers: {
        const textAnswer = answer as TextAnswer;
        return !!textAnswer.text;
      }
      case QuestionType.SingleAnswer: {
        const singleAnswer = answer as SingleAnswer;
        return !!singleAnswer.checked;
      }
      case QuestionType.FileAnswer: {
        const { files, text } = answer as FileAnswer;
        return (!!files?.length && files.some(({ file }) => 'url' in file && file.url)) || text;
      }
      case QuestionType.Matching: {
        const matchingAnswer = answer as MatchingAnswer;
        return Array.isArray(matchingAnswer) && matchingAnswer.every((item) => item?.text);
      }
      default:
        return false;
    }
  }
  return false;
};

export const prepareAnswerInput = (element: ExerciseElement, answer: Answer): CreateUserAnswerInput => {
  const { id } = element;
  if (!isQuestionElement(element)) return { elementId: id };
  const { questionType } = element;
  let answerInput: Omit<CreateUserAnswerInput, 'elementId'>;
  validateAnswerType(questionType, answer);

  switch (questionType) {
    case QuestionType.TextAnswers: {
      const textAnswer = answer as TextAnswer;
      answerInput = { textAnswerInput: { text: textAnswer.text } };
      break;
    }
    case QuestionType.FewAnswers: {
      const fewAnswer = answer as FewAnswers;
      answerInput = {
        fewAnswerInput: {
          elementPartIds: Object.keys(fewAnswer).filter((key) => fewAnswer[key].checked),
        },
      };
      break;
    }
    case QuestionType.Matching: {
      const matchingAnswer = answer as Array<MatchingAnswerItem>;
      answerInput = { matchingAnswerInput: { answers: matchingAnswer } };
      break;
    }
    case QuestionType.SingleAnswer: {
      const singleAnswer = answer as SingleAnswer;
      answerInput = { singleAnswerInput: { elementPartId: singleAnswer.checked } };
      break;
    }
    case QuestionType.FileAnswer: {
      const { files, text } = answer as FileAnswer;
      answerInput = {
        fileAnswerInput: {
          text,
          files: files?.reduce((acc: File[], currentFile) => {
            if ('url' in currentFile.file) return acc;
            acc.push(currentFile.file);
            return acc;
          }, []),
        },
      };
      console.log(answerInput);
      break;
    }
    default: {
      answerInput = {};
    }
  }

  return {
    elementId: id,
    ...answerInput,
  };
};
