import { Card, Grid, Typography, Box } from '@mui/material';
import { BackButton, NumberCircle } from 'components/index';
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { FC, useEffect, useMemo } from 'react';
import { QuestionType, useCommitProgressMutation, useGetHomeworkResultQuery } from '@generated/graphql';
import { LoadingButton } from '@mui/lab';
import { TestState } from 'pages/Lesson';
import { TaskStatus } from 'components/NumberCircle';
import CircularLoading from 'components/CircularLoading';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import IMAGES from 'constants/images';

type HomeworkResultParams = { courseId: string; exerciseId: string; blockId: string; lessonId: string };

const HomeworkResult: FC<{ isResult?: boolean }> = ({ isResult = false }) => {
  const navigate = useNavigate();
  const { subject, refetchLesson } = useOutletContext<IExerciseOutletContext>();
  const params = useParams<HomeworkResultParams>();
  const exerciseId = params.exerciseId as string;

  const { data, loading } = useGetHomeworkResultQuery({ variables: { exerciseId }, fetchPolicy: 'network-only' });
  const elements = data?.exercise?.elements;

  useEffect(() => {
    refetchLesson();
  }, []);

  const isComplete = data?.exercise?.completeness?.isComplete;
  const isHomeworkDone = isComplete || isResult;
  const userStatistic = data?.exercise.userStatistic;

  const scoreA: number = userStatistic?.scoreA || 0;
  const maxScoreA: number = userStatistic?.maxScoreA || 0;

  const totalCount = !isHomeworkDone ? elements?.length : maxScoreA;

  const doneTaskCount =
    useMemo(
      () => (isHomeworkDone ? scoreA : elements?.filter((e: any) => !!e.currentUserAnswers?.length)?.length),
      [JSON.stringify(elements), isHomeworkDone],
    ) || 0;

  const [saveProgress, { loading: saveProgressLoading }] = useCommitProgressMutation();

  const onSaveProgress = () => {
    saveProgress({
      variables: {
        exerciseId,
      },
    }).then(() => navigate(generatePath(`/${ROUTES.EXERCISE}/info`, params)));
  };

  const navigateToTask = (elementId: string) => {
    navigate({
      pathname: generatePath(`/${ROUTES.ELEMENT}`, { ...params, elementId }),
      search: `?state=${isHomeworkDone ? TestState.resultAnswer : TestState.previewAnswer}`,
    });
  };

  const getStatus = (element: any) => {
    if (element.currentUserProgress?.isCorrect === false) return TaskStatus.error;
    if (element.currentUserAnswers?.length || element.currentUserProgress?.isCorrect) return TaskStatus.answered;
    return TaskStatus.missed;
  };

  const tasksCircles = useMemo(
    () => (isHomeworkDone ? elements?.filter((element) => 'questionType' in element && element.questionType !== QuestionType.FileAnswer) : elements),
    [JSON.stringify(elements), isHomeworkDone],
  );

  const successPercent = totalCount ? (doneTaskCount / totalCount) * 100 : 0;

  let resultText = 'В следующий раз всё получится!';
  if (successPercent > 34 && successPercent < 67) resultText = 'Неплохо! Но ты можешь лучше!';
  else if (successPercent >= 67) resultText = 'Так держать!';

  if (loading) return <CircularLoading />;

  return (
    <>
      <Card sx={{ mb: 2.5 }}>
        <BackButton
          navigate={() =>
            isHomeworkDone
              ? navigate(generatePath(`/${ROUTES.BLOCK}`, params))
              : navigate({
                  pathname: generatePath(`/${ROUTES.EXERCISE}`, params),
                  search: `?state=${isHomeworkDone ? TestState.resultAnswer : TestState.previewAnswer}`,
                })
          }
          text={isHomeworkDone ? 'Вернуться к списку уроков' : 'Вернуться к выполнению'}
          sx={{ mb: 2.5 }}
        />
        <Typography variant='h2' sx={{ mb: 3.5 }}>
          {isHomeworkDone ? 'Ура! Вы сделали дз' : 'Просмотри свои ответы'}
        </Typography>
        <Grid container rowSpacing={3.5} mb={5}>
          {tasksCircles?.map((item: any) => {
            const status = getStatus(item);
            return (
              <Grid item xs={3} sx={{ justifyContent: 'center', display: 'flex' }} onClick={() => navigateToTask(item.id)}>
                <NumberCircle number={item.order} status={status} />
              </Grid>
            );
          })}
        </Grid>
        {isHomeworkDone && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1.5, mx: 'auto', width: 125 }} component='img' src={IMAGES[subject].goal} alt='goal' />
        )}
        <Typography variant='h3' textAlign='center' sx={{ color: 'base.300' }}>
          {`${doneTaskCount}/${totalCount}`}
        </Typography>
        <Typography variant='text2' textAlign='center'>
          {isHomeworkDone ? resultText : 'Выполнено заданий'}
        </Typography>
      </Card>
      {!isHomeworkDone && (
        <Box sx={{ flex: 1, display: 'flex' }} alignItems='flex-end' component='div'>
          <LoadingButton loading={saveProgressLoading} fullWidth variant='contained' color='secondary' subject={subject} onClick={() => onSaveProgress()}>
            Отправить тест
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

export default HomeworkResult;
