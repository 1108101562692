import { useController } from 'react-hook-form';
import React, { FC } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, SxProps, Typography } from '@mui/material';
import { CourseSubjectColor } from 'types/common';
import { CourseSubject } from '@generated/graphql';

export type TCheckboxProp = {
  name: string;
  label: string | React.ReactNode;
  subject?: CourseSubjectColor;
  disabled?: boolean;
  sx?: SxProps;
};

const CheckboxControl: FC<CheckboxProps & TCheckboxProp> = ({ name, label, disabled, subject, sx }) => {
  const {
    field: { value, onChange },
  } = useController({ name, defaultValue: false });

  const renderLabel = () => {
    if (typeof label === 'string') {
      return <Typography dangerouslySetInnerHTML={{ __html: label }} />;
    }
    return <Typography>{label}</Typography>;
  };

  return (
    <FormControlLabel
      checked={value}
      onChange={(e: any) => onChange(e.target.checked)}
      control={<Checkbox subject={subject || CourseSubject.Biology} disabled={disabled} />}
      label={renderLabel()}
      disabled={disabled}
      sx={sx}
    />
  );
};

export default CheckboxControl;
