import RadioButtonControl, { RadioButtonControlProps } from 'components/form/RadioButtonControl';
import { ComponentType } from 'react';
import CheckboxGroupControl, { CheckboxGroupControlProps } from 'components/form/CheckboxGroupControl';
import MatchingSelect, { MatchingSelectProps } from 'components/ElementsGenerator/inputs/MatchingSelect';
import File, { IFileProps } from 'components/ElementsGenerator/elements/File';
import { QuestionType } from '@generated/graphql';
import TextAnswer, { ITextAnswerProps } from 'components/ElementsGenerator/inputs/TextAnswer';
import { CourseSubjectColor } from 'types/common';

type AnswerPropsMap = {
  [QuestionType.TextAnswers]: ITextAnswerProps;
  [QuestionType.SingleAnswer]: RadioButtonControlProps;
  [QuestionType.FewAnswers]: CheckboxGroupControlProps;
  [QuestionType.FileAnswer]: IFileProps;
  [QuestionType.Matching]: MatchingSelectProps;
};

type AnswerComponent = {
  [K in QuestionType]: ComponentType<AnswerPropsMap[K]>;
};

const ANSWER_COMPONENT: AnswerComponent = {
  [QuestionType.TextAnswers]: TextAnswer,
  [QuestionType.SingleAnswer]: RadioButtonControl,
  [QuestionType.FewAnswers]: CheckboxGroupControl,
  [QuestionType.FileAnswer]: File,
  [QuestionType.Matching]: MatchingSelect,
};

type AnswerGeneratorProps<K extends QuestionType> = {
  questionType: K;
  name: string;
  subject: CourseSubjectColor;
  onlyNumbersInput?: boolean;
  disabled: boolean;
} & AnswerPropsMap[K];

const AnswerGenerator = <K extends QuestionType>({ questionType, name, subject, disabled, ...rest }: AnswerGeneratorProps<K>) => {
  if (questionType) {
    const Component = ANSWER_COMPONENT[questionType] as any;
    return (
      <Component
        key={`${questionType}-${name}`}
        questionType={questionType}
        handleChange={(value: string, onChange: any) => (rest.onlyNumbersInput ? onChange(value.replace(/\D/g, '').trim()) : onChange(value))}
        name={name}
        itemList={'elementParts' in rest ? rest.elementParts : undefined}
        placeholder={'placeholder' in rest ? rest.placeholder : undefined}
        subject={subject}
        form={'form' in rest ? rest.form : undefined}
        disabled={disabled}
        elementParts={'elementParts' in rest ? rest.elementParts : undefined}
        matchingList={'matchingList' in rest ? rest.matchingList : undefined}
      />
    );
  }
  return null;
};

export default AnswerGenerator;
