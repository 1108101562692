import { UseFormReturn } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import TextFieldWithAttachments from 'components/form/TextFieldWithAttachments';

export interface IFileProps {
  name: string;
  form: UseFormReturn;
  disabled?: boolean;
}

const File = ({ name, form, disabled }: IFileProps) => {
  const { reset, getValues } = form;

  const onCloseClick = () => {
    reset({
      ...getValues(),
      [name]: {
        text: '',
        files: [],
      },
    });
  };

  return (
    <Stack spacing={1}>
      <Typography variant='text3'>Прикрепи свой ответ</Typography>
      <TextFieldWithAttachments
        form={form}
        onCloseClick={onCloseClick}
        placeholder='Введи свой комментарий'
        disabled={disabled}
        messageField={`${name}.text`}
        filesField={`${name}.files`}
        messageRules={{
          validate: (value, formValues) => {
            const { files } = formValues[name];
            return !!files?.length || !!value || 'Прикрепите файлы или напишите текст';
          },
        }}
      />
    </Stack>
  );
};

export default File;
