import { Box, SxProps } from '@mui/material';
import { useState } from 'react';
import Lightbox from 'components/modals/Lightbox';

interface IImageProps {
  questionImg: string;
  sx?: SxProps;
}

const Image = ({ questionImg, sx }: IImageProps) => {
  const [openLightbox, setOpenLightBox] = useState(false);
  const toggleLightbox = () => {
    setOpenLightBox((prevState) => !prevState);
  };

  return (
    <>
      <Box
        tabIndex={0}
        role='button'
        component='div'
        onClick={toggleLightbox}
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 0px 9px 0px rgba(35, 35, 102, 0.19)',
          aspectRatio: 1,
          display: 'flex',
          '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
          ...sx,
        }}
      >
        <img src={questionImg} alt='question' />
      </Box>
      <Lightbox open={openLightbox} onClose={toggleLightbox} image={questionImg} />
    </>
  );
};

export default Image;
